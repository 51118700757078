<template>
  <v-card
    class="timer-container"
    color="primary"
    elevation="0"
    v-if="this.$root.mobile"
  >
    <div class="text-white" v-if="!end">
      <v-list-item class="">
        <v-list-item-content class="text-white">
            <div v-if="timer" class="timer">
              <span>{{ minutes }}</span>
              <span>:</span>
              <span>{{ seconds }}</span>
              <div style="color:red" v-if="end">
                <span>00:00</span>
              </div>
            </div>
            <div class="timer-notes pl-4">
              <div v-if="!timer">Click to Start Appointment</div>
              <div v-if="timer">Remaining Time</div>
            </div>
        </v-list-item-content>
        <div>
          <v-btn v-if="!timer" class="m-3" fab icon small @click="startTimer">
            <v-icon large color="white">mdi-play</v-icon>
          </v-btn>

          <v-btn v-if="timer" class="m-3" fab icon small @click="resetTimer">
            <v-icon large color="white">mdi-stop</v-icon>
          </v-btn>

          <v-btn v-if="timer" class="m-3" fab icon small @click="stopTimer">
            <v-icon large color="white">mdi-pause</v-icon>
          </v-btn>
        </div>
      </v-list-item>
    </div>

    <!-- <v-card-actions class="d-flex flex-wrap justify-center"> -->
    <!-- v-if="!timer" -->
    <!-- <v-btn class="m-3" fab icon @click="startTimer">
        <v-icon large color="white">mdi-play-circle</v-icon>
      </v-btn> -->

    <!-- v-if="timer" -->
    <!-- <v-btn class="m-3" fab icon @click="stopTimer">
        <v-icon large color="white">mdi-pause-circle</v-icon>
      </v-btn> -->

    <!-- v-if="resetButton" -->
    <!-- <v-btn class="m-3" fab icon @click="resetTimer">
        <v-icon large color="white">mdi-restart</v-icon>
      </v-btn> -->
    <!-- </v-card-actions> -->
  </v-card>
  <div
    class="timer-container"
    v-else
    style="position: fixed; top: 8px; right: 15px; width: 200px; height: 151px; z-index: 10; backgroundColor: #007bff;opacity: .4;"
  >
    <div class="text-white" v-if="!end">
      <div
        class="d-flex align-items-center  justify-space-between"
        style="flexDirection:column"
      >
        <div v-if="timer" class="timer">
          <span>{{ minutes }}</span>
          <span>:</span>
          <span>{{ seconds }}</span>
          <div style="color:red" v-if="end">
            <span>00:00</span>
          </div>
        </div>

        <div>
          <v-btn v-if="!timer" class="m-3" fab icon small @click="startTimer">
            <v-icon large color="white">mdi-play</v-icon>
          </v-btn>

          <v-btn v-if="timer" class="m-3" fab icon small @click="resetTimer">
            <v-icon large color="white">mdi-stop</v-icon>
          </v-btn>

          <v-btn v-if="timer" class="m-3" fab icon small @click="stopTimer">
            <v-icon large color="white">mdi-pause</v-icon>
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loaded: false,
      timer: null,
      totalTime: null,
      resetButton: false,
      // title: 'Start Session',
      end: false
    };
  },

  methods: {
    startTimer() {
      if (!this.totalTime) {
        return (this.title = "call duration not loadded");
      }
      this.timer = setInterval(() => this.countdown(), 1000);
      this.resetButton = true;
      // this.title = 'Session Started';
    },
    stopTimer() {
      clearInterval(this.timer);
      this.timer = null;
      this.resetButton = true;
      // this.title = 'Paused';
    },
    resetTimer() {
      this.totalTime = JSON.parse(localStorage.SETTINGS).call_duration * 60;
      clearInterval(this.timer);
      this.timer = null;
      this.resetButton = false;
      // this.title = 'Start Session';
    },
    padTime(time) {
      return (time < 10 ? "0" : "") + time;
    },
    countdown() {
      if (this.totalTime >= 1) {
        this.totalTime--;
      } else {
        this.totalTime = 0;
        this.end = true;
        this.resetTimer();
      }
    }
  },
  mounted() {
    this.totalTime = JSON.parse(localStorage.SETTINGS).call_duration * 60;
    this.loaded = true;
  },
  unmounted() {},

  computed: {
    minutes() {
      const minutes = Math.floor(this.totalTime / 60);
      return this.padTime(minutes);
    },
    seconds() {
      const seconds = this.totalTime - this.minutes * 60;
      return this.padTime(seconds);
    }
  }
};
</script>

<style>
.timer-container {
  font-family: Arial, Helvetica, sans-serif;
  width: 100%;
  border-radius: 0 !important;
  /* margin: 0 auto; */
}

.timer {
  font-size: 25px;
  font-weight: bolder;
}

.timer-notes {
  font-size: 13px;
  font-weight: bold;
}

.not-active {
  color: red;
}
</style>
