<template>
  <div>
    <!--begin::Dashboard-->
    <!--begin::Row-->

    <VideoApp
      v-if="app != null && app.type == 'video'"
      :room="app.video_room_id"
      :local_user="getOwner()"
      :remote_user="getRemote()"
      :date_time="app.date_time"
    />

    <ClinicAppointment v-if="app != null && app.type == 'clinic'" />
    <!--end::Row-->
    <!--end::Dashboard-->
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import VideoApp from "./comp/video.vue";
import ClinicAppointment from "./comp/ClinicAppointment";
//import DoctorDashboard from "@/view/pages/dash/doctor/doctor-dashboard.vue"

export default {
  name: "appointement",
  components: {
    VideoApp,
    ClinicAppointment
  },
  data() {
    return {
      appbar: "",
      role: "",
      app: null,
      userinfo: null
    };
  },
  beforeMount() {
    this.userinfo = JSON.parse(localStorage.userInfo);
  },
  mounted() {
    this.$root.title = this.$t("MENU.dashboard");
    this.getAppointment();
  },
  methods: {
    async getAppointment() {
      //var userInfo = JSON.parse(localStorage.userInfo).id;
      ApiService.get(
        "items",
        "appointments/" +
          this.$route.params.id +
          "?fields=id,status,video_room_id,rating,type,date,time,date_time,medical_file.owner.id," +
          "doctor.doctor_id.first_name,doctor.doctor_id.last_name,doctor.doctor_id.avatar.data,doctor.doctor_id.id," +
          "medical_file.owner.first_name,medical_file.owner.last_name,medical_file.owner.avatar.data,medical_file.id,medical_file.birthday,medical_file.gender,rating"
      )
        .then(({ data }) => {
          //console.log(data.data);
          this.app = data.data;
          this.$root.doctormf = this.app.medical_file.id;
        })
        .catch(error => {
          this.error = error;
        });
    },
    getOwner() {
      if (this.userinfo.id == this.app.medical_file.owner.id) {
        console.log("pateint is logging in");
        return this.app.medical_file.owner;
      } else if (this.userinfo.id == this.app.doctor.doctor_id.id) {
        console.log("doctor is logging in");
        return this.app.doctor.doctor_id;
      }
    },
    getRemote() {
      if (this.userinfo.id == this.app.medical_file.owner.id) {
        return this.app.doctor.doctor_id;
      } else if (this.userinfo.id == this.app.doctor.doctor_id.id) {
        return this.app.medical_file.owner;
      }
    }
  }
};
</script>
